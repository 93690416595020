import styled from "styled-components";
import Span from "../../components/Span";
import Button from "../../components/Button/Button";
export const LoginBody = styled.div`
  background-color: #2889d5;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const LoginContainer = styled.div`
  width: 335px;
  height: 548px;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const InputField = styled.div`
  border-radius: 30px;
  height: 30px;
  margin-left: 2px;
`;

export const Mb1 = styled.div`
  margin-top: 5px;
`;

export const Inputs = styled.div`
  margin-right: 5px;
`;

export const LoginForm = styled.div`
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  margin-top: 26px;
  padding-left: 25px;
`;

export const LoginTitle = styled.div`
  font-family: "Graphik-Bold";
  color: #000;
  text-align: center;
  padding-top: 20px;
  padding-right: 25px;
`;

export const LoginSubTitle = styled.div`
  font-family: "Graphik-Bold";
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  text-align: center;
`;

export const InputDiv = styled.div`
  display: inline-flex;
  width: 100%;
  margin-bottom: 14px;
`;

export const InputImg = styled.div`
  margin-right: 16px;
  margin-top: 5px;
`;

export const LoginBtn = styled.div`
  float: right;
  margin-right: 30%;
`;

export const UnameLabel = styled.div`
  border-radius: 10px;
`;

export const PasswordLabel = styled.div`
  font-size: 12px;
  position: absolute;
  top: 10px;
  left: 25px;
  z-index: 0;
`;

export const LoginFooter = styled.div`
  bottom: 25%;
  position: absolute;
  margin-left: 20%;
`;

export const LoginFooterImg = styled.div`
  bottom: 40%;
  position: inherit;
  margin-left: 20%;
`;
export const UnauthPageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;

  @media screen and (max-width: 1360px) {
    overflow: hidden;
  }
`;
export const LeftBannerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  max-width: 50%;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: auto;
  @media screen and (min-width: 1360px) {
    width: 672px;
    border: none;
  }
`;

export const ProductLogoContainer = styled.div`
  position: absolute;
  width: 211px;
  height: 144px;
  margin-top: 120px;
  margin-left: 76px;
`;

export const ProductNameText = styled.p`
  height: 28px;
  color: rgb(249, 250, 251);
  font-size: 24px;
  font-family: graphik-semibold;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 28px;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 64px);
  overflow: auto;
  background-color: #fff;
  @media screen and (max-width: 1360px) {
    height: auto;
  }
`;

export const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 46px 0 16px 0;
  @media screen and (max-width: 640px) {
    margin: 32px 0 16px 0;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 168px;
  width: 352px;

  @media screen and (max-width: 992px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 480px) {
    width: auto;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
`;

export const Col = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  position: relative;
  padding-right: ${(props) => (props.noPadding ? "0" : "8px")};
  padding-left: ${(props) => (props.noPadding ? "0" : "8px")};
  margin-bottom: 16px;
`;
export const FormHeading = styled.h4`
  color: rgb(21, 24, 27);
  font-size: 48px;
  font-family: graphik-bold;
  font-weight: bold;
  text-align: center;
  letter-spacing: -0.35px;
  line-height: 50px;
  margin: 0 0 8px 0;
`;
export const SmallDesp = styled(Span)`
  color: rgb(99, 115, 129);
  font-size: 18px;
  font-family: graphik;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.35px;
  line-height: 18px;
  margin: 0 0 16px 0;
  flex: 1;
  text-align: right;
  white-space: nowrap;
`;
export const GlobalInput = styled.input.attrs((props) => ({
  maxlength: props.maxLength || "",
  min: props.min || "",
}))`
  position: relative;
  height: 16px;
  color: rgb(21, 24, 27);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  outline: none;
  width: 100%;
  display: block;
  ::placeholder {
    height: 16px;
    color: rgb(145, 158, 171);
    font-size: 16px;
    font-family: graphik;
    font-weight: normal;
    letter-spacing: -0.35px;
    line-height: 16px;
    text-transform: capitalize;
  }
`;
export const GlobalInputLabel = styled.label`
  height: 11px;
  color: rgb(99, 115, 129);
  font-size: 11px;
  font-family: graphik-medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
`;
const Input = styled(GlobalInput)`
  background: #fff;
  border: 1px solid rgb(181, 192, 202);
  border-radius: 8px;
  height: 54px;
  padding: 8px 16px;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: ${(props) => (props.error ? "1.5px solid rgb(213, 0, 47)" : "")};

  &:focus {
    background: rgb(244, 251, 255);
    border: 1.5px solid rgb(1, 142, 255);
    box-shadow: 0px 0px 8px 0px rgba(102, 187, 255, 0.5);
  }
`;
export const InputLabel = styled(GlobalInputLabel)`
  padding: 2px 16px 6px 16px;

  &:after {
    color: ${(props) => (props.required ? "rgb(213, 0, 47)" : null)};
    content: "${(props) => (props.required ? "*" : null)}";
    top: 0;
    padding: 0 2px;
  }
`;
export const FloatingLabel = styled(InputLabel)`
  position: absolute;
  pointer-events: none;
  align-self: center;
  height: 16px;
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  text-transform: capitalize;
  transition: 0.4s ease all;
`;

export const FloatingInput = styled(Input)`
  height: 56px;
  transition: all 0.15s ease;
  &:-webkit-autofill,
  &:focus,
  &:not(:placeholder-shown) {
    padding: 27px 0 0 16px;
    + ${FloatingLabel} {
      padding: 0 16px;
      height: 11px;
      color: rgb(99, 115, 129);
      font-size: 11px;
      font-family: graphik-medium;
      font-weight: 500;
      letter-spacing: -0.25px;
      line-height: 11px;
      transform: translate3d(0, -12px, 0) scale(1);
    }
  }
`;

export const SecondaryButton = styled(Button)`
  background: ${(props) =>
    props.disabled ? "rgb(239, 242, 245)" : "rgb(255, 255, 255)"};
  border: 1.5px solid rgb(1, 142, 255);
  color: rgb(0, 142, 255);
  border-radius: 20px;
  height: 40px;
  width: auto;
  cursor: pointer;
  opacity: 1;
  font-family: graphik-medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
  padding: 10px 16px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #005eabbf;
    color: rgb(255, 255, 255);
  }
  &:focus {
    outline: #fff 1px solid;
  }
`;

export const PrimaryButton = styled(Button)`
  background: ${(props) =>
    props.disabled ? "rgb(231, 234, 238)" : "rgb(1, 142, 255)"};
  border: none;
  color: rgb(255, 255, 255);
  border-radius: 20px;
  height: 40px;
  width: auto;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.65" : "1")};
  font-family: graphik-medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
  padding: 10px 16px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #005eabbf;
  }
  &:focus {
    outline: #fff 1px solid;
  }
`;
export const HorizontalLine = styled.div`
  border-bottom: 1px solid rgb(181, 192, 202);
  width: ${(props) => props.width || ""};
`;
