import { createSelector } from "reselect";
import { initialState } from "./reducer";

export const selectLoginPageDomain = (state) =>
  state.get("loginPage", initialState);

export const selectErrorMessage = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("errorMessage")
  );

export const selectIsFetching = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("isFetching")
  );

export const selectIsAuthenticated = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("isAuthenticated")
  );

export const selectNumberOfWrongAttempts = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("numberOfWrongAttempts")
  );

export const selectSessionDetails = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("sessionDetails")
  );

export const selectUserName = () =>
  createSelector(selectLoginPageDomain, (substate) => substate.get("userName"));

export const selectPassword = () =>
  createSelector(selectLoginPageDomain, (substate) => substate.get("password"));

export const selectShowPassword = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("showPassword")
  );

export const selectSelectedLanguage = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("selectedLanguage")
  );

export const selectShowForgotPassword = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("showForgotPassword")
  );

export const selectEmail = () =>
  createSelector(selectLoginPageDomain, (substate) => substate.get("email"));

export const selectIsSendingForgotPassword = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("isSendingForgotPassword")
  );

export const selectCaptchaChars = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("captchaCharacters")
  );

export const selectShowResetPassword = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("showResetPassword")
  );

export const selectUserNameResetPassword = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("userNameToResetPwd")
  );

export const selectShowSecurityQuesScreen = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("showSecurityQuesScreen")
  );

export const selectNewPasswordWithSecQues = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("newPassword")
  );

export const selectConfirmPasswordWithSecQues = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("confirmPassword")
  );

export const selectSecurityQuesAns = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("securityAns")
  );

export const selectGetSecurityQuesStatus = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("getSecurityQuesStatus")
  );

export const selectUpdatePasswdBySecAnsStatus = () =>
  createSelector(selectLoginPageDomain, (substate) =>
    substate.get("updatePasswdBySecAnsStatus")
  );

export const selectClientDetailsFetchStatus = () =>
  createSelector(selectLoginPageDomain, (VPState) =>
    VPState.get("isClientDetailsFetching")
  );
export const selectClientDetails = () =>
  createSelector(selectLoginPageDomain, (VPState) =>
    VPState.get("clientDetails")
  );

export const selectEmailSentStatus = () =>
  createSelector(selectLoginPageDomain, (VPState) => VPState.get("emailSent"));
export const selectCheckUserExistStatus = () =>
  createSelector(selectLoginPageDomain, (VPState) =>
    VPState.get("isCheckUserExist")
  );
export const selectSecurityQuestionListStatus = () =>
  createSelector(selectLoginPageDomain, (VPState) =>
    VPState.get("isSecurityQuesList")
  );
export const selectSecurityQuestionList = () =>
  createSelector(selectLoginPageDomain, (VPState) =>
    VPState.get("securityQuestionList")
  );
export const selectPasswordChangedBySecQuesStatus = () =>
  createSelector(selectLoginPageDomain, (VPState) =>
    VPState.get("passwordChangedBySecQues")
  );
export const selectSecurityQuestion = () =>
  createSelector(selectLoginPageDomain, (VPState) => VPState.get("question"));
export const selectVerifySecurityQuestionAnswerStatus = () =>
  createSelector(selectLoginPageDomain, (VPState) =>
    VPState.get("verifySecurityQuestionAnswerStatus")
  );

// Accenture SSO login

export const selectAccentureSSOLoginFlag = () =>
  createSelector(selectLoginPageDomain, (VPState) =>
    VPState.get("setAccentureSSOLoginFlag")
  );

// Client SSO login

export const selectClientSSOLoginFlag = () =>
  createSelector(selectLoginPageDomain, (VPState) =>
    VPState.get("setClientSSOLoginFlag")
  );
// to check the type of login accenture or client sso
export const selectSSOType = () =>
  createSelector(selectLoginPageDomain, (VPState) => VPState.get("SsoType"));

export const selectMaxIdleTimeAllowed = () =>
  createSelector(selectLoginPageDomain, (VPState) =>
    VPState.get("maxIdleTimeAllowed")
  );
export const selectAboutToLogout = () =>
  createSelector(selectLoginPageDomain, (VPState) =>
    VPState.get("aboutToLogout")
  );

  export const selectIsSsoLoginfailed = () => createSelector(selectLoginPageDomain,(VPState)=>VPState.get('isSsoLoginFailed'))

  export const selectMFAOTPSentStatus = () =>
    createSelector(selectLoginPageDomain, (VPState) => VPState.get("MFAOTPSent"));
  export const selectAuthenticatingLogin = () =>
    createSelector(selectLoginPageDomain, (VPState) =>
      VPState.get("authenticating")
    );
    export const selectNotYetConfigured = () =>
      createSelector(selectLoginPageDomain, (VPState) =>
        VPState.get("notYetConfigured")
      );
      export const selectSecurityOTPStatus = () =>
        createSelector(selectLoginPageDomain, (VPState) => VPState.get("securityOTP"));
      export const selectSubmittedQnAStatus = () =>
        createSelector(selectLoginPageDomain, (VPState) =>
          VPState.get("isSubmittedQnA")
        );