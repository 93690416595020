import styled from "styled-components";


const ErrorText = styled.div`
  margin-top: 0.5rem;
  color:  rgb(213, 0, 47);
  font-size: var(--fs-12);
  width: 100%;
`;

export default ErrorText;
