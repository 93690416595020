import { useCallback } from "react";

export default function useHandleOTPKeypress({
  setCurrentIndex,
  inputRefsArray,
  btnRef,
}) {
  return useCallback(
    ({ keyCode }) => {
      try {
        if (keyCode < 48 || keyCode > 57) return;
        setCurrentIndex((prevIndex) => {
          // calculate the next input index, next input after the final input will be again the first input. you can change the logic here as per your needs
          const nextIndex = prevIndex < 6 - 1 ? prevIndex + 1 : prevIndex;
          const nextInput = inputRefsArray?.[nextIndex]?.current;
          if (prevIndex === 5) {
            btnRef.current.focus();
            return prevIndex;
          }
          if (nextInput) {
            nextInput.focus();
            nextInput.select();
          }
          return nextIndex;
        });
      } catch (error) {
        console.log(error);
      }
    },
    [btnRef, inputRefsArray, setCurrentIndex]
  );
}
