import { fromJS } from "immutable";
import {
  CHANGING_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  INIT_UPDATE_PASSWORD_ON_LOGIN,
  UPDATE_PASSWORD_ON_LOGIN_SUCCESS,
  UPDATE_PASSWORD_ON_LOGIN_FAILED,
  PROFILE_MFA_OTP_SENT,
} from "./actionTypes";

export const initialState = fromJS({
  changingPassword: false,
  passwordUpdated: false,
  errorMessage: "",
  changePasswordOnLogin: false,
  profileOTPSent: false,
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_MFA_OTP_SENT:
      return state.set("profileOTPSent", true);
    case CHANGING_PASSWORD: {
      return state.set("changingPassword", true);
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return state.set("changingPassword", false).set("passwordUpdated", true)
      .set("profileOTPSent", false);
    }
    case CHANGE_PASSWORD_FAILED: {
      return state
        .set("errorMessage", action.message)
        .set("changingPassword", false)
        .set("passwordUpdated", false);
    }
    case INIT_UPDATE_PASSWORD_ON_LOGIN: {
      return state.set("changePasswordOnLogin", true);
    }
    case UPDATE_PASSWORD_ON_LOGIN_SUCCESS: {
      return state.set("changePasswordOnLogin", false);
    }
    case UPDATE_PASSWORD_ON_LOGIN_FAILED: {
      return state.set("changePasswordOnLogin", false);
    }
    default:
      return state;
  }
};

export default reducer;
