import { createSelector } from "reselect";
import { initialState } from "./reducers";

const selectChangePassword = (state) =>
  state.get("changePassword", initialState);

const selectChangingPassword = () =>
  createSelector(selectChangePassword, (VPState) =>
    VPState.get("changingPassword")
  );
const selectPasswordUpdated = () =>
  createSelector(selectChangePassword, (VPState) =>
    VPState.get("passwordUpdated")
  );
const selectResetForm = () =>
  createSelector(selectChangePassword, (VPState) => VPState.get("resetForm"));
const selectChangePasswordOnLogin = () =>
  createSelector(selectChangePassword, (VPState) => VPState.get("changePasswordOnLogin"));
 const selectPasswordChangeOTPStatus = () =>
  createSelector(selectChangePassword, (VPState) => VPState.get("profileOTPSent"));
export { selectChangingPassword, selectPasswordUpdated, selectResetForm, selectChangePasswordOnLogin,selectPasswordChangeOTPStatus };
