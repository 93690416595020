import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  UPDATE_FIELD_VALUE,
  UPDATE_SHOW_PASSWORD,
  UPDATE_LANGUAGE_CHANGE,
  UPDATE_SHOW_FORGOT_PASSWORD,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  UPDATE_SHOW_RESET_PASSWORD,
  GET_SECURITY_QUESTION_SUCCESS,
  GET_SECURITY_QUESTION_FAILURE,
  UPDATE_SHOW_SECURITY_QUES_SCREEN,
  INIT_UPDATE_PASSWORD_BY_SECURITY_ANS,
  UPDATE_PASSWD_SEC_ANS_SUCCESS,
  UPDATE_PASSWD_SEC_ANS_FAILURE,
  INIT_FORGOT_PASSWORD,
  INIT_LOGOUT,
  CHECK_AUTH_TIMEOUT,
  CHECK_AUTH_STATUS,
  INIT_GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_FETCH_COMPLETED,
  FETCH_GET_CLIENT_DETAILS,
  FETCHING_GET_CLIENT_DETAILS,
  BACK_TO_LOGIN,
  INIT_CHECK_USER_EXIST,
  FETCHING_CHECK_USER_EXIST_COMPLETED,
  FETCHING_CHECK_USER_EXIST,
  INIT_LIST_SECURITY_QUESTION,
  FETCHING_LIST_SECURITY_QUESTION,
  FETCHED_LIST_SECURITY_QUESTION,
  FETCH_LIST_SECURITY_QUESTION_COMPLETED,
  UPDATING_PASSWORD_SEC_ANS,
  INIT_VERIFY_SECURITY_QUESTION_ANSWER,
  VERIFYING_SECURITY_QUESTION_ANSWER,
  VERIFYING_SECURITY_QUESTION_ANSWER_SUCCESS,
  VERIFYING_SECURITY_QUESTION_ANSWER_FAILED,
  SET_ACCENTURE_SSO_LOGIN_FLAG,
  SET_CLIENT_SSO_LOGIN_FLAG,
  INIT_REFRESH_TOKEN,
  FETCHING_REFRESH_TOKEN,
  FETCH_REFRESH_TOKEN,
  REFRESH_TOKEN_FETCH_FAILED,
  INIT_CONFIRM_GDPR_MESSAGE,
  CONFIRMING_GDPR_MESSAGE,
  GDPR_MESSAGE_CONFIRMED,
  GDPR_MESSAGE_CONFIRM_FAILED,
  INIT_IDLE_TIMER,
  INIT_IDLE_TIMER_COUNTDOWN,
  TIMER_RESET_SUCCESS,
  ABOUT_TO_LOGOUT,
  LOGOUT,
  REFRESH_TOKEN,
  SSO_LOGIN_FAILED,
  INIT_MFA_OTP,
  MFA_OTP_FAILED,
  MFA_OTP_SENT,
  NOT_YET_CONFIGURED,
  INIT_FORGOT_PASS_MFA_OTP,
  FORGOT_PASS_MFA_OTP_FAILED,
  SUBMITTING_QNA_SUCCESS,
  FORGOT_PASS_MFA_OTP_SENT,
  SUBMITTING_QNA_FAILED,
  SUBMITTING_QNA,
} from "./actionTypes";

export function requestLogin(credential, loginType, token, callback) {
  return {
    type: LOGIN_REQUEST,
    credential,
    loginType,
    token,
    callback,
  };
}
export const initMFAOTP = (payload) => ({ type: INIT_MFA_OTP, payload });
export const MFAFailed = () => ({ type: MFA_OTP_FAILED })
export const MFASuccess = () => ({ type: MFA_OTP_SENT })
export function loginSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    data,
  };
}


export function loginFailure(message) {
  return {
    type: LOGIN_FAILURE,
    message,
  };
}
export function ssoLoginFailed(data) {
  return {
    type: SSO_LOGIN_FAILED,
    data,
  };
}

export function updateFieldValue(field) {
  return {
    type: UPDATE_FIELD_VALUE,
    field,
  };
}

export function updateShowPassword(showPassword) {
  return {
    type: UPDATE_SHOW_PASSWORD,
    showPassword,
  };
}

export function updateLanguageChange(language) {
  return {
    type: UPDATE_LANGUAGE_CHANGE,
    language,
  };
}

export function updateShowForgotPassword(showForgotPassword) {
  return {
    type: UPDATE_SHOW_FORGOT_PASSWORD,
    showForgotPassword,
  };
}

export function submitForgotPasswordAction() {
  return {
    type: FORGOT_PASSWORD_REQUEST,
  };
}

export function forgotPasswordSuccess() {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
  };
}

export function forgotPasswordFailure() {
  return {
    type: FORGOT_PASSWORD_FAILURE,
  };
}

export function updateShowResetPassword(showResetPassword) {
  return {
    type: UPDATE_SHOW_RESET_PASSWORD,
    showResetPassword,
  };
}

export function getSecurityQuesSuccess() {
  return {
    type: GET_SECURITY_QUESTION_SUCCESS,
  };
}

export function getSecurityQuesFailure() {
  return {
    type: GET_SECURITY_QUESTION_FAILURE,
  };
}

export function updateShowSecurityQuestionScreen(showSecurityQuesScreen) {
  return {
    type: UPDATE_SHOW_SECURITY_QUES_SCREEN,
    showSecurityQuesScreen,
  };
}

export function initUpdatePasswordBySecurityAns(data) {
  return {
    type: INIT_UPDATE_PASSWORD_BY_SECURITY_ANS,
    data,
  };
}

export function updatePasswordSecAnsSuccess() {
  return {
    type: UPDATE_PASSWD_SEC_ANS_SUCCESS,
  };
}

export function updatePasswordSecAnsFailed() {
  return {
    type: UPDATE_PASSWD_SEC_ANS_FAILURE,
  };
}

export function initForgotPassword(data) {
  return {
    type: INIT_FORGOT_PASSWORD,
    data,
  };
}

export const logout = (callback) => {
  return {
    type: INIT_LOGOUT,
    callback,
  };
};
export const loggedout = () => {
  return {
    type: LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return {
    type: CHECK_AUTH_TIMEOUT,
    expirationTime,
  };
};

export const checkAuthStatus = () => {
  return {
    type: CHECK_AUTH_STATUS,
  };
};

// get client details
export const initGetClientDetails = () => {
  return {
    type: INIT_GET_CLIENT_DETAILS,
  };
};
export const fetchingGetClientDetails = () => {
  return {
    type: FETCHING_GET_CLIENT_DETAILS,
  };
};
export const fetchGetClientDetails = (data) => {
  return {
    type: FETCH_GET_CLIENT_DETAILS,
    data: data,
  };
};
export const fetchGetClientDetailsCompleted = () => {
  return {
    type: GET_CLIENT_DETAILS_FETCH_COMPLETED,
  };
};
export const BackToLogin = () => {
  return {
    type: BACK_TO_LOGIN,
  };
};
export const fetchingCheckUserExist = () => {
  return {
    type: FETCHING_CHECK_USER_EXIST,
  };
};
export const fetchCheckUserExistCompleted = () => {
  return {
    type: FETCHING_CHECK_USER_EXIST_COMPLETED,
  };
};
export const initCheckUserExist = (data, callBackFunction) => {
  return {
    type: INIT_CHECK_USER_EXIST,
    data,
    callBackFunction,
  };
};
export const initListSecurityQuestions = () => {
  return {
    type: INIT_LIST_SECURITY_QUESTION,
  };
};
export const fetchingListSecurityQuestions = () => {
  return {
    type: FETCHING_LIST_SECURITY_QUESTION,
  };
};
export const fetchedListSecurityQuestions = (data) => {
  return {
    type: FETCHED_LIST_SECURITY_QUESTION,
    data,
  };
};
export const fetchListSecurityQuestionsCompleted = () => {
  return {
    type: FETCH_LIST_SECURITY_QUESTION_COMPLETED,
  };
};
export const updatingPasswordSecAns = () => {
  return {
    type: UPDATING_PASSWORD_SEC_ANS,
  };
};
export const initVerifySecurityQuestionAnswer = (data, callBackFunction) => {
  return {
    type: INIT_VERIFY_SECURITY_QUESTION_ANSWER,
    data,
    callBackFunction,
  };
};
export function verifyingSecurityQuestionAnswer() {
  return {
    type: VERIFYING_SECURITY_QUESTION_ANSWER,
  };
}

export function verifySecurityQuestionAnswerSuccess() {
  return {
    type: VERIFYING_SECURITY_QUESTION_ANSWER_SUCCESS,
  };
}

export function verifySecurityQuestionAnswerFailed() {
  return {
    type: VERIFYING_SECURITY_QUESTION_ANSWER_FAILED,
  };
}

// Accenture SSO login
export const setAccentureSSOLoginFlag = (SsoType) => {
  return {
    type: SET_ACCENTURE_SSO_LOGIN_FLAG,
    SsoType,
  };
};

// Client SSO login
export const setClientSSOLoginFlag = (SsoType) => {
  return {
    type: SET_CLIENT_SSO_LOGIN_FLAG,
    SsoType,
  };
};

export const initIdleTimer = () => {
  return {
    type: INIT_IDLE_TIMER,
  };
};
export const initIdleTimerCountdown = (timeInSecs) => {
  return {
    type: INIT_IDLE_TIMER_COUNTDOWN,
    timeInSecs,
  };
};
export const aboutToLogout = () => {
  return {
    type: ABOUT_TO_LOGOUT,
  };
};

export const timerResetSuccess = () => {
  return {
    type: TIMER_RESET_SUCCESS,
  };
};

export const initRefreshToken = (expirationTimeInSec) => {
  return {
    type: INIT_REFRESH_TOKEN,
    expirationTimeInSec,
  };
};
export const refreshToken = () => {
  return {
    type: REFRESH_TOKEN,
  };
};
export const fetchingRefreshToken = () => {
  return {
    type: FETCHING_REFRESH_TOKEN,
  };
};
export const fetchRefreshToken = (data) => {
  return {
    type: FETCH_REFRESH_TOKEN,
    data: data,
  };
};
export const refreshTokenFetchFailed = () => {
  return {
    type: REFRESH_TOKEN_FETCH_FAILED,
  };
};

export const initConfirmGDPRMsg = () => {
  return {
    type: INIT_CONFIRM_GDPR_MESSAGE,
  };
};
export const confirmingGDPRMsg = () => {
  return {
    type: CONFIRMING_GDPR_MESSAGE,
  };
};
export const gdprMsgConfirmed = () => {
  return {
    type: GDPR_MESSAGE_CONFIRMED,
  };
};
export const gdprMsgConfirmFailed = () => {
  return {
    type: GDPR_MESSAGE_CONFIRM_FAILED,
  };
};

export const notYetConfigured = () => {
  return {
    type: NOT_YET_CONFIGURED,
  };
};
export const initSecurityOTP = (payload) => ({
  type: INIT_FORGOT_PASS_MFA_OTP,
  payload,
});
export const securityOTPFailed = () => ({ type: FORGOT_PASS_MFA_OTP_FAILED });
export const securityOTPSuccess = () => ({ type: FORGOT_PASS_MFA_OTP_SENT });
export function submittingQnASuccess() {
  return {
    type: SUBMITTING_QNA_SUCCESS,
  };
}
export function submittingQnAFailed() {
  return {
    type: SUBMITTING_QNA_FAILED,
  };
}
export function submittingQnA() {
  return {
    type: SUBMITTING_QNA,
  };
}

