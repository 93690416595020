import {
  INIT_CHANGE_PASSWORD,
  CHANGING_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  INIT_UPDATE_PASSWORD_ON_LOGIN,
  UPDATE_PASSWORD_ON_LOGIN_SUCCESS,
  UPDATE_PASSWORD_ON_LOGIN_FAILED,
  INIT_PROFILE_MFA_OTP,
  PROFILE_MFA_OTP_SENT,
  PROFILE_MFA_OTP_FAILED,
} from "./actionTypes";

export function initChangePassword(payload, callback) {
  return {
    type: INIT_CHANGE_PASSWORD,
    payload,
    callback,
  };
}
export function changingPassword() {
  return {
    type: CHANGING_PASSWORD,
  };
}
export function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
}
export function changePasswordFailed() {
  return {
    type: CHANGE_PASSWORD_FAILED,
  };
}

export function initUpdatePasswordOnLogin(payload, callBackFunction) {
  return {
    type: INIT_UPDATE_PASSWORD_ON_LOGIN,
    payload,
    callBackFunction,
  };
}

export function updatePasswordOnLoginSuccess() {
  return {
    type: UPDATE_PASSWORD_ON_LOGIN_SUCCESS,
  };
}
export function updatePasswordOnLoginFailed() {
  return {
    type: UPDATE_PASSWORD_ON_LOGIN_FAILED,
  };
}
export const initProfileMFAOTP = (payload) => ({
  type: INIT_PROFILE_MFA_OTP,
  payload,
});
export const passwordChangeOTPFailed = () => ({ type: PROFILE_MFA_OTP_FAILED });
export const passwordChangeOTPSuccess = () => ({
  type: PROFILE_MFA_OTP_SENT,
});