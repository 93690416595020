export const statusCode = {
  LC: [
    {
      code: "001",
      message: "Login successful",
    },
    {
      code: "002",
      message: "Login Failed Due To Login Type MisMatch",
    },
    {
      code: "003",
      message: "Change Password On Login",
    },
    {
      code: "004",
      message: "Account is locked.",
    },
    {
      code: "005",
      message: "Login Failed Due To Password Expiry",
    },
    {
      code: "006",
      message: "No such username or password",
    },
    {
      code: "007",
      message: "Incorrect Password Attempts exceed.",
    },
    {
      code: "008",
      message: "Some Error Occurred",
    },
    {
      code: "009",
      message: "OldPassword Can't be Same As New Password",
    },
    {
      code: "010",
      message: "Old Password Is Incorrect",
    },
    {
      code: "011",
      message:
        "Sorry, Password can't be changed as New Password already exists in your latest Password history.",
    },
    {
      code: "012",
      message: "Some Error Occurred",
    },
    {
      code: "013",
      message: "OTP is no longer valid, Time Range exceeded.",

    },
    {
      code: "015",
      message: "Password Changed Successfully",
    },
    {
      code: "016",
      message: "User Is Not Confirmed",
    },
    {
      code: "017",
      message: "Login failed due to incorrect username or password",
    },
    {
      code: "022",
      message: "Please Make Sure To Enter UserName And Password Values",
    },
    {
      code: "023",
      message: "Password and Confirm Password doesn't Match",
    },
    {
      code: "024",
      message: "Username is already taken",
    },
    {
      code: "028",
      message: "Some Error Occurred",
    },
    {
      code: "029",
      message: "New Password Can't be Same as Old Password",
    },
    {
      code: "030",
      message: "Old Password Is Incorrect",
    },
    {
      code: "031",
      message:
        "Sorry, Password can't be changed as New Password already exists in your latest Password history.",
    },
    {
      code: "032",
      message: "Some Error Occurred",
    },
    {
      code: "034",
      message: "Password Change Fails As User Account Is Locked",
    },
    {
      code: "035",
      message: "Password Changed Successfully",
    },
    {
      code: "051",
      message: "User does not exist",
    },
    {
      code: "036",
      message: "Wrong security question for this user.",
    },
    {
      code: "037",
      message: "Security question has not been set up for your account.",
    },
    {
      code: "038",
      message:
        "Sorry, the answer does not match with your security question & answer.",
    },
    {
      code: "039",
      message: "Password and Confirm password do not match.",
    },
    {
      code: "052",
      message: "OTP provided is incorrect.",
    },
    {
      code: "053",
      message:
        "Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
    },
    {
      code: "055",
      message: "User is rejected.",
    },
    {
      code: "056",
      message:
        "Sorry, your account is locked as you have exceeded the number of attempts. Please contact helpdesk team.",
    },
    {
      code: "054",
      message: "Link/Token has been expired.",
    },
    {
      code: "060",
      message: "User Doesn't Exists in Records.",
    },
    {
      code: "058",
      message: "Client Doesn't Exist.",
    },
    {
      code: "059",
      message: "Token Expired.",
    },
    {
      code: "061",
      message: "Invalid Login.",
    },
  ],
  UC: [
    {
      code: "002",
      message: "Username contains spaces",
    },
    {
      code: "005",
      message: "Username is not correct",
    },
    {
      code: "004",
      message: "Invalid Template format",
    },
    {
      code: "006",
      message: "Invalid Column Name",
    },
    {
      code: "007",
      message: "Maximum Upload reached, Must be less than 1000",
    },
    {
      code: "008",
      message: "Uploaded excel has empty rows. Please check.",
    },
    {
      code: "010",
      message:
        "GroupName Already In Use(It may or may not be visible in the List as it may not be active currently)",
    },
    {
      code: "011",
      message: "User Details Invalid",
    },
    {
      code: "012",
      message: "Please provide the correct token number",
    },
    {
      code: "013",
      message: "OTP is no longer valid, Time Range exceeded",
    },
    {
      code: "020",
      message: "Number Of Sheets Allowed is 1",
    },
    {
      code: "021",
      message: "User Approval is under progress for this username",
    },
    {
      code: "022",
      message: "User Email Already Exists",
    },
    {
      code: "023",
      message: "Please assign suppliers to the Account",
    },
    {
      code: "057",
      message: "User Roles already exists ! Please try again",
    },
  ],
  SC: [
    {
      code: "021",
      message: "Primary Email already exists.",
    },
    {
      code: "022",
      message: "Location already exists.",
    },
    {
      code: "031",
      message:
        "Proxy cannot be taken as the company is already assigned to Helpdesk.",
    },
    {
      code: "032",
      message:
        "Proxy cannot be taken. Some error occured, please contact admin.",
    },
  ],
  RECONC: [
    {
      code: "016",
      message: "Uploaded excel has empty rows. Please check.",
    },
    {
      code: "017",
      message:
        "Uploaded excel cannot have empty columns apart from PO Number field. Please check.",
    },
    {
      code: "018",
      message:
        "S.No field can only be numeric in the uploaded file. Please check.",
    },
    {
      code: "019",
      message:
        "Currency field is incorrect in the uploaded excel. Please check.",
    },
    {
      code: "020",
      message:
        "PO Number field is incorrect in the uploaded excel. Please check.",
    },
    {
      code: "021",
      message: "Number Of Sheets Allowed is 1",
    },
    {
      code: "022",
      message:
        "Invoice Amount field is incorrect in the uploaded excel. Please check.",
    },
    {
      code: "023",
      message: "Incorrect company name. Please check.",
    },
    {
      code: "024",
      message: "Maximum records exceeded in the attachment. Please check.",
    },
    {
      code: "025",
      message:
        "Date in the uploaded excel did not match the date format selected. Please check.",
    },
    {
      code: "026",
      message:
        "Incorrect header name found in input file.Please download the file with proper format from UI.",
    },
  ],
  SUBC: [
    {
      code: "001",
      message: "Invalid File . Please upload a file with valid extension",
    },
    {
      code: "002",
      message: "No Records found in file.Please try again",
    },
    {
      code: "003",
      message: "Duplicate entries found.Please try again",
    },
    {
      code: "004",
      message:
        "PO Number + Supplier details not found.Please verfiy the Invoice Metadata.",
    },
    {
      code: "005",
      message: "Duplicate file name found.Please try again",
    },
    {
      code: "006",
      message: "Supllier Name Not Found",
    },
    {
      code: "007",
      message: "Please upload all the attachments as per the template.",
    },
    {
      code: "016",
      message:
        "Submission(s) Created.Please navigate to submission history to open the submission and navigate to IPA screen for PO matching",
    },
    {
      code: "017",
      message:
        "Submission failed, try again. Verify the details provided in metadata attachment.",
    },
    {
      code: "018",
      message: "Attachment Improper",
    },
    {
      code: "019",
      message: "sourceCoulmns and DestinationColumns are required",
    },
    {
      code: "020",
      message: "Excel Column  has null value",
    },
    {
      code: "022",
      message: "Number Of Sheets Allowed is 1",
    },
    {
      code: "050",
      message: "Input file extension is not allowed",
    },
    {
      code: "051",
      message: "Input file contains .exe extension",
    },
  ],
  EINV: [
    {
      code: "001",
      message:
        "Sorry, this invoice already exists in our system. Please retry submitting a new invoice.",
    },
    {
      code: "050",
      message: "Input file extension is not allowed",
    },
    {
      code: "051",
      message: "Input file contains .exe extension",
    },
  ],
  AC: [
    {
      code: "001",
      message: "Attribute name already exists",
    },
  ],
  ET: [
    {
      code: "001",
      message: "Email Template name already exists",
    },
  ],
  CLC: [{ code: "001", message: "Custom list Already exists" }],
};
