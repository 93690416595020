import { useEffect } from "react";

export default function useKeyupListener({
  inputRefsArray,
  handleKeyPress,
  show,
}) {
  useEffect(() => {
    if (inputRefsArray?.[0]?.current) {
      inputRefsArray[0].current.focus();
    }

    if (show) {
      window.addEventListener("keyup", handleKeyPress, false);
    }

    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleKeyPress, inputRefsArray, show]);
}