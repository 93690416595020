import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import "react-notifications-component/dist/theme.css";
import ReactNotification, { store } from "react-notifications-component";
import "./App.css";
import "antd/dist/antd.css";

import {
  selectClientDetails,
  selectClientDetailsFetchStatus,
  selectIsAuthenticated,
  selectSessionDetails,
} from "./container/LoginPage/store/selectors";

import { Route, Switch, Redirect } from "react-router-dom";
import AuthRoutes from "./routes/AuthRoutes";
import { generateFilteredRoutes } from "./routes/generateFilteredRoutes";

import Layout from "./components/Layout";

import Div from "./components/Div";
import Loader from "./components/Loader";
import { sessionDetails } from "./config";
import {
  checkAuthStatus,
  initConfirmGDPRMsg,
  initGetClientDetails,
  logout,
} from "./container/LoginPage/store/actions";

import { reducerDesc, sagaDesc } from "./helper/ReducerSagaInjector";
import getReducerInjectors from "./utils/reducerInjectors";
import getSagaInjectors from "./utils/sagaInjectors";
import { initGetNotification } from "./container/HomePage/store/actions";
import Axios from "./config/axios";
import { buildNotification } from "./config/notification";
import { getNavigatorLanguage, kickout } from "./helper";
import GDPRMessage from "./components/Message/GDPRMessage";
import { initUserRole } from "./container/UserManagement/store/actions";
import { initCountryFetch } from "./store/actions";
const BlankPage = React.lazy(() => import("./container/BlankPage"));
const ResetPassword = React.lazy(() => import("./container/ResetPassword"));
// const SSOLoginPage = React.lazy(() => import("./container/SSOLoginPage/index"));

const LoginPage = React.lazy(() => import("./container/LoginPage"));
const signUpPage = React.lazy(() => import("./container/SignUp"));
const UpdatePasswordOnLogin = React.lazy(() =>
  import("./container/ChangePassword/updatePasswordOnLogin")
);

const ConfirmAccount = React.lazy(() =>
  import("./container/RequestsManagement/ConfirmAccount")
);

const UnauthLoader = React.lazy(() =>
  import("./container/BlankPage/UnauthLoader")
);

const middleLoaderStyle = {
  position: "fixed",
  left: "50%",
  top: "50%",
};

class App extends Component {
  prevAuth = false;
  reducerInjector = getReducerInjectors(this.props.store);
  sagaInjector = getSagaInjectors(this.props.store);
  timerInterval;
  constructor(props) {
    super(props);
    this.state = {
      forceLogout: false,
      showGDPR: true,
    };
    const { injectReducer } = this.reducerInjector;
    const { injectSaga } = this.sagaInjector;

    reducerDesc.map(({ key, reducer }) => injectReducer(key, reducer));
    sagaDesc.map(({ key, saga }) => injectSaga(key, { saga }));
  }

  componentDidMount() {
    const session =
      JSON.parse(sessionStorage.getItem("sessionDetails")) || sessionDetails;

    this.props.autologin();

    if (
      session.isAuthenticated &&
      window.location.href.includes("confirm-account")
    ) {
      this.setState({
        forceLogout: true,
      });
      this.props.logout(() => {
        const lang = getNavigatorLanguage();
        this.props.i18n.changeLanguage(lang);
        const notification = buildNotification({
          message: "notification.logoutSuccess",
          type: "success",
        });
        store.addNotification({
          ...notification,
          dismiss: {
            duration: 3000,
          },
        });
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.isAuthenticated !== prevProps.isAuthenticated &&
      this.props.isAuthenticated
    ) {
      this.props.getNotification();
      this.props.getClientDetails();
      this.props.i18n.changeLanguage(this.props.sessionDetails.languageId);
      this.props.getCountries();
      this.props.getUserRole();
    }
  }

  render() {
    if (
      !this.state.forceLogout &&
      !this.props.isAuthenticated &&
      this.props.isAuthenticated !== this.prevAuth
    ) {
      kickout();

      return <Loader />;
    }
    const { search } = this.props.history.location || {};

    this.prevAuth = this.props.isAuthenticated;
    const middleLoader = (
      <Div {...middleLoaderStyle}>
        <Loader />
      </Div>
    );
    const session =
      JSON.parse(sessionStorage.getItem("sessionDetails")) || sessionDetails;
    const clientDetails =
      JSON.parse(sessionStorage.getItem("clientDetails")) || {};
    const client = this.props.clientDetails || "";
    const isClientSSO =
      JSON.parse(sessionStorage.getItem("isClientSSO")) || false;

    let appRoutes = null;
    let application = null;
    const { menus, token } = this.props.sessionDetails.menus
      ? this.props.sessionDetails
      : session;
    if (this.props.isAuthenticated) {
      Axios.defaults.headers.Authorization = `Bearer ${token}`;
      Axios.defaults.headers["X-App-Info"] = clientDetails.token;
    }
    // get auth routes i.e. private routes
    if (this.props.isAuthenticated && !this.state.forceLogout) {
      if (client) {
        const routes = generateFilteredRoutes(menus, client);

        appRoutes = (
          <Suspense fallback={middleLoader}>
            <Switch>
              <Route path="/" component={() => AuthRoutes(true, routes)} />
              <Redirect to="/" />
            </Switch>
          </Suspense>
        );

        application = <Layout>{appRoutes}</Layout>;
      } else {
        application = middleLoader;
      }
    } else {
      appRoutes = (
        <Suspense fallback={middleLoader}>
          <Switch>
            <Route path="/sign-up" component={signUpPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/confirm-account" component={ConfirmAccount} />
            <Route
              path="/update-password-on-login"
              component={UpdatePasswordOnLogin}
            />
            <Route
              path="/app/callback"
              component={isClientSSO ? UnauthLoader : LoginPage}
            />
            <Route
              path="/"
              component={
                search && search.toLowerCase().includes("ss0")
                  ? BlankPage
                  : LoginPage
              }
            />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      );

      application = appRoutes;
    }
    return (
      <React.Fragment>
        <Div id="error-wrapper" left="0" position="absolute">
          {/* {this.props.aboutToLogout && session && session.isAuthenticated ? (
            <LogoutMessage
              show={this.state.showLogoutWarning}
              onClose={() => this.setState({ showLogoutWarning: false })}
            />
          ) : null} */}
          {this.props.isAuthenticated && session.displayGDPR ? (
            <GDPRMessage
              show={this.state.showGDPR}
              gdprMessage={session.gdprMessage}
              confirmGDPR={this.props.confirmGDPR}
              onClose={() => this.setState({ showGDPR: false })}
            />
          ) : null}
          <ReactNotification />
        </Div>
        {application}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  sessionDetails: selectSessionDetails(),
  clientDetails: selectClientDetails(),
  gettingClientDetails: selectClientDetailsFetchStatus(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    autologin: (callback) => {
      dispatch(checkAuthStatus(callback));
    },
    getNotification: () => {
      dispatch(initGetNotification());
    },
    logout: (callback) => {
      dispatch(logout(callback));
    },
    getClientDetails: () => {
      dispatch(initGetClientDetails());
    },
    confirmGDPR: () => {
      dispatch(initConfirmGDPRMsg());
    },
    getUserRole: () => {
      dispatch(initUserRole());
    },
    getCountries: () => {
      dispatch(initCountryFetch());
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(withTranslation()(App));
