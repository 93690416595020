import React from "react";
import Loader from "../Loader";
// import { ButtonWrapper } from "../../components/Div/divStyles";
import ErrorText from "../ErrorText";
import IMG from "../IMG";
import ModalComponent from "../Modal";
import {
  Controls,
  Desc,
  VerificationDetails,
  ButtonWrapper,
} from "./style";

import shield from "../../assets/images/shield-check.svg";
import { useTranslation } from "react-i18next";
import OTPContainer from "./style";

import { useState, createRef, useRef, useEffect, useCallback } from "react";
import useHandleOTPKeypress from "./hooks/useHandleOTPKeypress";
import useKeyupListener from "./hooks/useKeyupListener";
import Button from "../Button/Button";
import { PrimaryButton } from "../../container/LoginPage/styles";
const waitTime = process.env.REACT_APP_OTP_TIMER || 60;

export default function MFAOTP({
  show = false,
  otp = [],
  loading = false,
  handleOTPChange,
  handleOTPResent,
  onSubmit,
}) {
  let timeout = useRef(null);
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [resendTime, setResendTimer] = useState(waitTime);
  const [resentCount, setResentCount] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (show && resentCount <= 5)
      timeout.current = setInterval(
        () => setResendTimer((prevTimer) => prevTimer - 1),
        1000
      );
    return () => {
      setResendTimer(waitTime);
      clearTimeout(timeout.current);
    };
  }, [resentCount, show]);
  useEffect(() => {
    if (resendTime < 0) clearTimeout(timeout.current);
  }, [resendTime]);
  const [inputRefsArray] = useState(() =>
    Array.from({ length: 6 }, () => createRef())
  );
  const btnRef = useRef();
  const handleKeyPress = useHandleOTPKeypress({
    setCurrentIndex,
    inputRefsArray,
    btnRef,
  });
  useKeyupListener({
    inputRefsArray,
    show,
    handleKeyPress,
  });
  const resentOTP = useCallback(
    (e) => {
      if (resentCount > 5) return;
      setResentCount((prevCount) => prevCount + 1);
      handleOTPResent(e);
    },
    [handleOTPResent, resentCount]
  );

  const checkOTPComplete = () => {
    const complete =
      otp.length === 6 &&
      otp.every((digit) => digit !== "" && digit !== undefined);
    setIsComplete(complete);
  };

  useEffect(() => {
    checkOTPComplete();
  }, [otp]);

  const OTPInputs = inputRefsArray.map((ref, i) => {
    let value = "";
    if (otp[i]) value = otp[i];
    return (
      <input
        ref={ref}
        key={`otp${i}`}
        maxLength="1"
        onChange={({ target }) => handleOTPChange(target, currentIndex)}
        value={value}
        onFocus={() => setCurrentIndex(i)}
        autoFocus={i === 0}
      />
    );
  });

  return (
    <ModalComponent
      preventOutterClickClose
      noHeader
      // centered
      show={show}

      // maxWidth="32em"
    >
      <OTPContainer>
        <VerificationDetails>
          <h2>My Supplier Portal</h2>
          <IMG src={shield} alt="Verification" />
          <Desc>{t("msp.otpDialog")}</Desc>
          <form onSubmit={onSubmit}>
            <Controls>{OTPInputs}</Controls>
            {loading ? (
              <Loader />
            ) : (
              <div>
                <ButtonWrapper>
                <PrimaryButton
                  type="submit"
                  bgColor="#018eff"
                  textColor="#FFF"
                  ref={btnRef}
                  // onClick={props.applyFilter} add if submit is not working
                  disabled={loading || !isComplete}
                >
                  {t("common.submitButton")}
                </PrimaryButton>
                <PrimaryButton
                  onClick={resentOTP}
                  disabled={resentCount >= 5 || resendTime > 0}
                >
                  {t("msp.resend")}
                </PrimaryButton>
                </ButtonWrapper>
                {resendTime > 0 ? (
                  <ErrorText>{t("msp.resendOTPMsg", { resendTime })}</ErrorText>
                ) : null}
                </div>
            )}
          </form>
        </VerificationDetails>
      </OTPContainer>
    </ModalComponent>
  );
}
