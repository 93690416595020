import { ApiConfig } from "../../../config/apiConfig";

const BASE_API_URL = ApiConfig();

export const LOGIN_URL = BASE_API_URL + "Login/authenticate";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const INIT_MFA_OTP = "INIT_MFA_OTP";
export const MFA_OTP_SENT = "MFA_OTP_SENT";
export const MFA_OTP_FAILED = "MFA_OTP_FAILED";
export const NOT_YET_CONFIGURED = "NOT_YET_CONFIGURED";

export const UPDATE_FIELD_VALUE = "UPDATE_FIELD_VALUE";

export const UPDATE_SHOW_PASSWORD = "UPDATE_SHOW_PASSWORD";

export const UPDATE_LANGUAGE_CHANGE = "UPDATE_LANGUAGE_CHANGE";

export const UPDATE_SHOW_FORGOT_PASSWORD = "UPDATE_SHOW_FORGOT_PASSWORD";
export const FORGOT_PASSWORD_URL = BASE_API_URL + "Login/forgotPassword";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const UPDATE_SHOW_RESET_PASSWORD = "UPDATE_SHOW_RESET_PASSWORD";
export const GET_SECURITY_QUESTION_SUCCESS = "GET_SECURITY_QUESTION_SUCCESS";
export const GET_SECURITY_QUESTION_FAILURE = "GET_SECURITY_QUESTION_FAILURE";
export const UPDATE_SHOW_SECURITY_QUES_SCREEN =
  "UPDATE_SHOW_SECURITY_QUES_SCREEN";
export const INIT_UPDATE_PASSWORD_BY_SECURITY_ANS =
  "INIT_UPDATE_PASSWORD_BY_SECURITY_ANS";
export const UPDATE_PASSWD_SEC_ANS_SUCCESS = "UPDATE_PASSWD_SEC_ANS_SUCCESS";
export const UPDATE_PASSWD_SEC_ANS_FAILURE = "UPDATE_PASSWD_SEC_ANS_FAILURE";
export const INIT_FORGOT_PASSWORD = "INIT_FORGOT_PASSWORD";

export const INIT_LOGOUT = "INIT_LOGOUT";
export const LOGOUT = "LOGOUT";
export const CHECK_AUTH_TIMEOUT = "CHECK_AUTH_TIMEOUT";
export const CHECK_AUTH_STATUS = "CHECK_AUTH_STATUS";

// Get Client Details
export const INIT_GET_CLIENT_DETAILS = "INIT_GET_CLIENT_DETAILS";
export const FETCHING_GET_CLIENT_DETAILS = "FETCHING_GET_CLIENT_DETAILS";
export const FETCH_GET_CLIENT_DETAILS = "FETCH_GET_CLIENT_DETAILS";
export const GET_CLIENT_DETAILS_FETCH_COMPLETED =
  "GET_CLIENT_DETAILS_FETCH_COMPLETED";
export const BACK_TO_LOGIN = "BACK_TO_LOGIN";
export const INIT_CHECK_USER_EXIST = "INIT_CHECK_USER_EXIST";
export const FETCHING_CHECK_USER_EXIST_COMPLETED =
  "FETCHING_CHECK_USER_EXIST_COMPLETED";
export const FETCHING_CHECK_USER_EXIST = "FETCHING_CHECK_USER_EXIST";

export const INIT_LIST_SECURITY_QUESTION = "INIT_LIST_SECURITY_QUESTION";
export const FETCHING_LIST_SECURITY_QUESTION =
  "FETCHING_LIST_SECURITY_QUESTION";
export const FETCHED_LIST_SECURITY_QUESTION = "FETCHED_LIST_SECURITY_QUESTION";
export const FETCH_LIST_SECURITY_QUESTION_COMPLETED =
  "FETCH_LIST_SECURITY_QUESTION_COMPLETED";

export const UPDATING_PASSWORD_SEC_ANS = "UPDATING_PASSWORD_SEC_ANS";
export const INIT_VERIFY_SECURITY_QUESTION_ANSWER =
  "INIT_VERIFY_SECURITY_QUESTION_ANSWER";
export const VERIFYING_SECURITY_QUESTION_ANSWER =
  "VERIFYING_SECURITY_QUESTION_ANSWER";
export const VERIFYING_SECURITY_QUESTION_ANSWER_SUCCESS =
  "VERIFYING_SECURITY_QUESTION_ANSWER_SUCCESS";
export const VERIFYING_SECURITY_QUESTION_ANSWER_FAILED =
  "VERIFYING_SECURITY_QUESTION_ANSWER_FAILED";

// Accenture SSO login
export const SET_ACCENTURE_SSO_LOGIN_FLAG = "SET_ACCENTURE_SSO_LOGIN_FLAG";

// Client SSO login
export const SET_CLIENT_SSO_LOGIN_FLAG = "SET_CLIENT_SSO_LOGIN_FLAG";

export const ABOUT_TO_LOGOUT = "ABOUT_TO_LOGOUT";
// export const RESET_TIMEOUT = "RESET_TIMEOUT";
export const TIMER_RESET_SUCCESS = "TIMER_RESET_SUCCESS";

export const INIT_IDLE_TIMER = "INIT_IDLE_TIMER";
export const INIT_IDLE_TIMER_COUNTDOWN = "INIT_IDLE_TIMER_COUNTDOWN";

export const INIT_REFRESH_TOKEN = "INIT_REFRESH_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const FETCHING_REFRESH_TOKEN = "FETCHING_REFRESH_TOKEN";
export const FETCH_REFRESH_TOKEN = "FETCH_REFRESH_TOKEN";
export const REFRESH_TOKEN_FETCH_FAILED = "REFRESH_TOKEN_FETCH_FAILED";

export const INIT_CONFIRM_GDPR_MESSAGE = "INIT_CONFIRM_GDPR_MESSAGE";
export const CONFIRMING_GDPR_MESSAGE = "CONFIRMING_GDPR_MESSAGE";
export const GDPR_MESSAGE_CONFIRMED = "GDPR_MESSAGE_CONFIRMED";
export const GDPR_MESSAGE_CONFIRM_FAILED = "GDPR_MESSAGE_CONFIRM_FAILED";

export const SSO_LOGIN_FAILED = "SSO_LOGIN_FAILED";
export const INIT_FORGOT_PASS_MFA_OTP = "INIT_FORGOT_PASS_MFA_OTP";
export const FORGOT_PASS_MFA_OTP_SENT = "FORGOT_PASS_MFA_OTP_SENT";
export const FORGOT_PASS_MFA_OTP_FAILED = "FORGOT_PASS_MFA_OTP_FAILED";
export const SUBMITTING_QNA_SUCCESS = "SUBMITTING_QNA_SUCCESS";
export const SUBMITTING_QNA = "SUBMITTING_QNA";
export const SUBMITTING_QNA_FAILED = "SUBMITTING_QNA_FAILED";